export function useAppwrite() {
  const config = useRuntimeConfig().public

  function imagePreview(bucketId: string, fileId: string) {
    // ?width=40&height=40&output=webp
    return `${config.base.url}/api/v1/files/${bucketId}/${fileId}/preview`
  }
  function image(bucketId: string, fileId: string) {
    return `${config.base.url}/api/v1/files/${bucketId}/${fileId}`
  }

  return {
    image,
    imagePreview,
  }
}
